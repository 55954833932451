import {
  getLinkWithNextParam,
  handleRedirectionWithSignupSource,
} from '@common/utils'
import { NAVBAR_LINKS } from '@lib/constants/common'
import { Trans } from '@lingui/macro'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'

const NavbarNewLink = dynamic(() =>
  import('@components/common/navbar-new-link')
)
const LanguageSwitcher = dynamic(() =>
  import('@components/common/language-switcher')
)
const CampsDropdown = dynamic(() =>
  import('@components/common/navbar/navbar-dropdowns/camps-dropdown')
)

const {
  LOGIN,
  REGISTER,
  JOIN_CLASS_LOGGED_OUT,
  COURSES,
  QUIZZES,
  COMPETITIONS,
  BLOG,
  HOME,
} = NAVBAR_LINKS

type Props = {
  isQuizChampionship?: boolean
  hideMobileMenu?: () => void
  customCTAText: string
  customCTAonClick: () => void
  nextPage: string
}

const LoggedOutMobileItems = ({
  isQuizChampionship,
  hideMobileMenu,
  customCTAText,
  customCTAonClick,
  nextPage,
}: Props) => {
  const router = useRouter()

  return (
    <div>
      <nav className="flex flex-col px-10 space-y-5">
        <Link href={HOME.link}>
          <a className="text-xl text-grey font-500" onClick={hideMobileMenu}>
            <Trans id={HOME.text?.id} values={HOME.text?.values} />
          </a>
        </Link>

        <a
          className="mt-3 text-xl text-grey font-500"
          target="_blank"
          rel="noopener noreferrer"
          href={COURSES.link}
        >
          <Trans id={COURSES.text?.id} values={COURSES.text?.values} />
        </a>
        <CampsDropdown />

        <Link href={COMPETITIONS.link}>
          <a className="text-xl text-grey font-500" onClick={hideMobileMenu}>
            <Trans
              id={COMPETITIONS.text?.id}
              values={COMPETITIONS.text?.values}
            />
          </a>
        </Link>
        {isQuizChampionship && (
          <NavbarNewLink
            link={QUIZZES.link}
            text={QUIZZES.text}
            hideMobileMenu={hideMobileMenu}
          />
        )}

        <a
          className="mt-3 text-xl text-grey font-500"
          target="_blank"
          rel="noopener noreferrer"
          href={BLOG.link}
        >
          <Trans id={BLOG.text?.id} values={BLOG.text?.values} />
        </a>

        {customCTAText ? (
          <>
            <hr className="mt-5 mb-3 text-grey-500" />
            <button
              onClick={customCTAonClick}
              className="px-5 py-2 mt-4 text-lg text-center btn btn-orange sm:px-10"
            >
              {customCTAText}
            </button>
          </>
        ) : (
          <>
            <LanguageSwitcher />
            <button
              className="text-xl text-left text-grey font-500"
              onClick={() =>
                handleRedirectionWithSignupSource(
                  router.asPath,
                  getLinkWithNextParam(LOGIN.link, nextPage)
                )
              }
            >
              <Trans id={LOGIN.text?.id} values={LOGIN.text?.values} />
            </button>
            <hr className="mt-5 mb-3 text-grey-500" />
            <button
              onClick={() =>
                handleRedirectionWithSignupSource(
                  router.asPath,
                  getLinkWithNextParam(REGISTER.link, nextPage)
                )
              }
              className="px-5 py-2 mt-5 text-lg text-center bg-white btn btn-orange text-orange sm:px-10"
            >
              <Trans id={REGISTER.text?.id} values={REGISTER.text?.values} />
            </button>
            <button
              onClick={() => {
                handleRedirectionWithSignupSource(
                  router.asPath,
                  getLinkWithNextParam(
                    JOIN_CLASS_LOGGED_OUT.link,
                    JOIN_CLASS_LOGGED_OUT.next
                  )
                )
              }}
              className="px-5 py-2 mt-4 text-lg text-center btn btn-orange sm:px-10"
            >
              <Trans
                id={JOIN_CLASS_LOGGED_OUT.text?.id}
                values={JOIN_CLASS_LOGGED_OUT.text?.values}
              />
            </button>
          </>
        )}
      </nav>
    </div>
  )
}
export default LoggedOutMobileItems
